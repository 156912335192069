import React, { useLayoutEffect, lazy, Suspense } from 'react';
import { Route, useLocation, Routes } from 'react-router';
import Spinner from './pages/Spinner';
import Login from "./pages/user/Login";
import Registration from "./pages/user/Registration";
import Dashboard from './pages/dashboard/Dashboard';
import Profile from './pages/user/profile/Profile'
import PrivateRoutes from './PrivateRoutes';
import TravelerItinerary from './pages/traveler-itinerary/TravelerItinerary';
import TravelerItineraryList from './pages/traveler-itinerary/TravelerItineraryList';
import TravelersList from './pages/travelers/TravelersList';
import AddTraveler from './pages/travelers/AddTraveler';
import UpdateTraveler from './pages/travelers/UpdateTraveler';
import ItineraryTimeline from './pages/traveler-itinerary/ItineraryTimeline';

const LoginRoutes = () => {
  const location = useLocation()
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" exact element={<Profile />}></Route>
            <Route path="/profile" element={<Profile />}></Route>
            <Route path="/traveler-itinerary-list" element={<TravelerItineraryList />}></Route>
            <Route path="/traveler-itinerary" element={<TravelerItinerary />}></Route>
            <Route path="/travelers-list" element={<TravelersList />}></Route>
            <Route path="/add-travelers" element={<AddTraveler />}></Route>
            <Route path="/update-traveler" element={<UpdateTraveler />}></Route>
            <Route path="/itinerary" element={<ItineraryTimeline />}></Route>
          </Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/sign-up" element={<Registration />}></Route>
          <Route path="/" element={<Login />}></Route>
        </Routes>
      </Suspense>
    </div>
  )
}

export default LoginRoutes
