import React, { useEffect, useState } from 'react'
import Profileheader from '../user/profile/ProfileHeader'
import '../user/profile/profile.css'
import { Card, Col, Row } from 'react-bootstrap'
import { Box, Container, TextField, Select, MenuItem, InputLabel, FormControl, Button, Typography, Divider, FormHelperText } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import { SvgIcon } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
// import profileimg from '../../../assets/images/profile/profileimg.jpg';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useDispatch, useSelector } from 'react-redux'
import { API_BASE_URL, FILE_PATH, PROFILE_IMAGE } from '../../utility/apiConstants'
// import profileImg from "../../../assets/images/images/myprofile.png";
import AttachmentIcon from '@mui/icons-material/Attachment';
// import shortid from "https://cdn.skypack.dev/shortid@2.2.16";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import SideNav from '../../SideNav'
import MainFooter from '../footer/MainFooter'
import { countrylist } from '../../services/countryList';
import { authDelete, authPost, getAuth, getReq, post } from '../../utility/apiConfig'
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from '@mui/material/Autocomplete';
import { panVali, passportVali } from '../user/validation';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from 'react-bootstrap/Modal';

const UpdateTraveler = () => {
    const currentURL = window.location.href;
    var user_id = currentURL.split("?")[1]
    const [passportIssue, setPassportIssue] = useState();
    const [passportExpiry, setPassportExpiry] = useState();
    const [dateOfBith, setDateOfBith] = useState();
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState([]);
    const [selectedfile, SetSelectedFile] = useState([]);
    const [docFile, setDocFile] = useState([]);
    const [Files, SetFiles] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [userDetails, setUserDetails] = useState({
        title: "", firstName: "", middleName: "", lastName: "",
        email: "", countryCode: "", state: "", phone: "",
        designation: "", city: "", country: "", countryOrigin: "",
        numOfPax: "", selectedDocument: "", userId: ""
    });
    const [userDetailsErr, setUserDetailsErr] = useState({
        title: null, firstName: null, middleName: null, lastName: null,
        email: null, countryCode: null, phone: null,
        designation: null, city: null, country: null, state: null,
        numOfPax: null
    });
    const [additionalUserDetails, setAdditionalUserDetails] = useState([]);
    const [passportDetails, setPassportDetails] = useState({
        pancard: "", passport: "", country: "", countryOrigin: "", nationality: "",
    });
    const [passportDetailsErr, setPassportDetailsErr] = useState({
        pancard: null, passport: null, country: null, nationality: null,
        passportIssue: null, passportExpiry: null, dateOfBith: null
    });

    const [documents, setDocuments] = useState([{
        id: 0, selectedDocument: "", files: []
    }]);
    const [relation, setRelation] = useState('');

    const handleRelationChange = (event) => {
        setRelation(event.target.value);
    };
    const docTypeArr = {
        "pan": "PAN",
        "aadhar_card": "Aadhar Card",
        "passport_bio": "Passport Bio Page",
        "passport_cover": "Passport Cover Page",
        "photograph": "Photograph",
        "others": "Others"
    }

    const handleChange = (e) => {
        // setAge(event.target.value);
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
        setUserDetailsErr({ ...userDetailsErr, [e.target.name]: null });
    }

    const handlePassportDetails = (e) => {
        setPassportDetails({ ...passportDetails, [e.target.name]: e.target.value });
        setPassportDetailsErr({ ...passportDetailsErr, [e.target.name]: null });
    }

    useEffect(() => {
        let scripts = 'https://unpkg.com/libphonenumber-js/bundle/libphonenumber-js.min.js'
        var script = document.createElement("script");
        script.src = scripts
        script.async = true;
        document.body.appendChild(script);
    }, [])

    useEffect(() => {
        getStateList();
        getCurrentUserDetails()
    }, [])

    const getCurrentUserDetails = () => {
        getAuth(API_BASE_URL + `user/list_of_users?user_id=${user_id}`, null)
            .then((response) => {
                let user_data = response.data.data.data.form_data;
                let user_details = {
                    ...userDetails,
                    title: user_data.title,
                    firstName: user_data.first_name,
                    middleName: user_data.middle_name,
                    lastName: user_data.last_name,
                    email: user_data.email,
                    countryCode: user_data.country_code_value,
                    state: user_data.state,
                    phone: user_data.phone,
                    designation: user_data.designation,
                    city: user_data.city,
                    country: user_data.country,
                    countryOrigin: user_data.country_code,
                    numOfPax: user_data.no_of_traveler,
                    userId: user_data.user_id
                }
                setRelation(user_data?.relationship)
                let passport_details = {}
                let doc_details = {}
                setUserDetails(user_details);
                passport_details = { ...passportDetails, country: user_data.country, countryOrigin: user_data.country_code }
                setPassportDetails(passport_details)
                getCityList(user_data.country_code);
                if (response.data?.data?.data?.pax_travel_info?.length > 0) {
                    let user_Travel_details = response.data?.data?.data?.pax_travel_info[0];
                    setPassportDetails({
                        ...passportDetails,
                        pancard: user_Travel_details.pan,
                        passport: user_Travel_details.passport_no,
                        country: countrylist.filter(x => x.origin === user_Travel_details.passport_issuing_country)[0]?.name,
                        countryOrigin: user_Travel_details.passport_issuing_country,
                        nationality: user_Travel_details.nationality
                    })

                    setPassportIssue(formatDate2(user_Travel_details.passport_date_of_issue));
                    setPassportExpiry(formatDate2(user_Travel_details.passport_date_of_expiry));
                    setDateOfBith(formatDate2(user_Travel_details.dob));
                }
                if (response.data?.data?.data?.travel_docs?.length > 0) {
                    setDocFile(response.data.data.data?.travel_docs);
                }
            }).catch((error) => {
                setStates([])
            })
    }
    //console.log("additionalUserDetails", additionalUserDetails)
    function formatDate2(date) {
        let dd = date.split("-")[2];
        let mm = Number(date.split("-")[1]) - 1;
        let yy = date.split("-")[0];
        let newDate = dayjs().date(dd).month(mm).year(yy);
        return newDate;
    }
    const getStateList = () => {
        getReq(API_BASE_URL + 'data/india_state', null)
            .then((response) => {
                setStates(response.data.data.data);
                let st = response.data.data.data;
                st.find((state) => {
                    if (state.origin === userDetails.state) {
                        setUserDetails({ ...userDetails, state: state });
                        setUserDetailsErr({ ...userDetailsErr, state: null });
                    }
                })
            }).catch((error) => {
                setStates([])
            })
    }

    const getCityList = (countryId) => {
        try {
            getReq(API_BASE_URL + 'data/get_cities/' + countryId, null)
                .then((response) => {
                    setCities(response.data.data.data);
                }).catch((error) => {
                    setCities([])
                })
        } catch (error) {

        }
    }

    const handlePhoneChange = (e) => {
        setUserDetailsErr({ ...userDetailsErr, phone: null });
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setUserDetails({ ...userDetails, phone: onlyNums })
        } else if (onlyNums.length === 10) {
            const number = onlyNums;
            setUserDetails({ ...userDetails, phone: number })
        }
    }

    const handleTitleChange = (e) => {
        setUserDetails({ ...userDetails, title: e.target.value });
        setUserDetailsErr({ ...userDetailsErr, title: null });
    };

    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const onDocumentChange = (e, i) => {
        let newForm = [...documents]
        let objIndex = newForm.findIndex(((obj, index) => index == i));
        newForm[objIndex][e.target.name] = e.target.value
        setDocuments(newForm)
    }

    //     const onAditionalDocumentChange = (item,i,index) => {
    //         const newArray = [...additionalUserDetails];
    //         let newForm = [...documents]
    //         let objIndex = newForm.findIndex(((obj, index) => index == i));
    //         newForm[objIndex][e.target.name] = e.target.value
    // 0    }

    const InputChange = (e, ind) => {
        // --For Multiple File Input
        let newForm = [...documents]
        let objIndex = newForm.findIndex(((obj, index) => index == ind));
        const file = e.target.files[0]
        var fileSize = parseFloat(file.size / 1000).toFixed(2);
        if (fileSize > 2024) {
            alert("Please upload a file Less than 2 Mb");
            return false;
        }

        if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png" && file.type != "application/pdf") {
            alert("Please select a JPG/JPEG/PNG/PDF file for upload");
            return;
        }
        let images = [];
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {
                let data = documents[ind].files
                data = [
                    ...data,
                    {
                        // id: shortid.generate(),
                        id: Math.floor(Math.random() * 100000),
                        filename: e.target.files[i].name,
                        filetype: e.target.files[i].type,
                        fileimage: reader.result,
                        datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                        filesize: filesizes(e.target.files[i].size)
                    }
                ];
                newForm[objIndex].files = data
                setDocuments(newForm)
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    }


    const DeleteSelectFile = (id, i, ind) => {
        let newForm = [...documents]
        let objIndex = newForm.findIndex(((obj, index) => index == ind));

        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = newForm[objIndex].files.filter((data) => data.id !== id);
            newForm[objIndex].files = result
            setDocuments(newForm)
        } else {
            // alert('No');
        }

    }

    const onFormAddition = () => {
        setDocuments([...documents, { id: documents.length, selectedDocument: "", files: [] }]);
    }
    const onFormDelete = (index) => {
        let newForm = documents.filter(item => item.id !== index);
        let oneMore = newForm.map((x, index) => { return ({ ...x, id: index }) })
        setDocuments(oneMore);
    }

    const FileUploadSubmit = async (e) => {
        e.preventDefault();
        // form reset on submit 
        e.target.reset();
        if (selectedfile.length > 0) {
            for (let index = 0; index < selectedfile.length; index++) {
                SetFiles((preValue) => {
                    return [
                        ...preValue,
                        selectedfile[index]
                    ]
                })
            }
            SetSelectedFile([]);
        } else {
            alert('Please select file')
        }
    }


    const DeleteFile = async (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = Files.filter((data) => data.id !== id);
            SetFiles(result);
        } else {
            // alert('No');
        }
    }

    const handleUpdate = () => {
        setLoading(true);
        const { userId, title, firstName, middleName, lastName, email, countryCode, state, phone, designation, city, country, countryOrigin, numOfPax } = userDetails
        let dataToSend = {
            user_id: userId,
            title: title,
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            // email: email,
            state: state,
            phone: phone,
            designation: designation,
            city: city,
            country: countryOrigin,
            relationship: relation,
            role: "admin"
        }

        try {
            authPost(API_BASE_URL + 'user/update_account', dataToSend)
                .then((response) => {
                    if (response.data.status) {
                        alert(response.data.message)
                        setLoading(false);
                        window.location.reload();
                    } else {
                        alert(response.data.message)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    alert('Somthing went wrong please try again')
                    setLoading(false);
                })
        } catch (error) {

        }
    }

    function formatDate(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${da}-${mo}-${ye}`
        return newDate;
    }

    const handleDocUpdate = () => {
        if(documents.filter(item => item.files.length === 0).length > 0){
            alert("Please upload documents");
            return
        }
        setLoading(true);
        let dataToSend = documents.map(item => {
            return ({
                doc_type: item.selectedDocument,
                doc_file: item.files?.map(x => x.fileimage),
                user_id: userDetails.userId,
                role: "admin"
            })
        })
        try {
            authPost(API_BASE_URL + 'user/update_travel_docs', dataToSend)
                .then((response) => {
                    if (response.data.status) {
                        alert(response.data.message)
                        setLoading(false);
                        window.location.reload();
                    } else {
                        alert(response.data.message)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    alert('Somthing went wrong please try again')
                    setLoading(false);
                })
        } catch (error) {

        }
    }
// console.log("Passprt details", passportDetails)
    const checkPassportValidation = () => {
        const { pancard, passport, country, countryOrigin, nationality } = passportDetails;
        //let panErr = pancard === "" ? null : panVali(pancard?.toUpperCase());
        let passportErr = passport === "" ? null : passportVali(passport?.toUpperCase());
        let countryErr = passport === "" ? null : countryOrigin === "" ? "Please select country" : null;
        let nationalityErr = passport === "" ? null : nationality === "" ? "Please enter nationality" : null;
        let passIssueErr = passport === "" ? null : passportIssue === undefined ? "Please select Passport Date of Issue" : null;
        let passExpiryErr = passport === "" ? null : passportExpiry === undefined ? "Please select Passport Date of Expiry" : null;
        let dobErr = dateOfBith === undefined ? "Please select date of birth" : null;

        if ( passportErr || countryErr || nationalityErr || passIssueErr || passExpiryErr || dobErr) {
            setPassportDetailsErr({
                ...passportDetailsErr,
               // pancard: panErr,
                passport: passportErr,
                country: countryErr,
                nationality: nationalityErr,
                passportIssue: passIssueErr,
                passportExpiry: passExpiryErr,
                dateOfBith: dobErr
            })
            return
        }
        else {
            handleSubmitPassportDetails();
        }
    }

    const handleSubmitPassportDetails = () => {
        const { pancard, passport, country, countryOrigin, nationality } = passportDetails;
        let dataToSend = {
            user_id: userDetails.userId,
            pan: pancard?.toUpperCase(),
            passport_no: passport?.toUpperCase(),
            passport_issuing_country: countryOrigin,
            nationality: nationality,
            passport_date_of_issue: formatDate(new Date(passportIssue)),
            passport_date_of_expiry: formatDate(new Date(passportExpiry)),
            dob: formatDate(new Date(dateOfBith)),
            role: "admin"
        }

        try {
            authPost(API_BASE_URL + 'user/update_pax_travel_info', dataToSend)
                .then((response) => {
                    if (response.data.status) {
                        alert(response.data.message)
                        setLoading(false);
                        window.location.reload();
                    } else {
                        alert(response.data.message)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    alert('Somthing went wrong please try again')
                    setLoading(false);
                })
        } catch (error) {

        }
    }

    const handleDocModal = (item) => {
        if (item) {
            setSelectedDoc(item);
        }
        setModalVisible(!modalVisible);
    }

    const handleDocDelete = (item) => {
        if (window.confirm("Are you sure you want to delete this file?")) {
            try {
                authDelete(API_BASE_URL + `user/delete_travel_docs/${item.origin}`)
                    .then((response) => {
                        if (response.data.status) {
                            alert(response.data.message)
                            setLoading(false);
                            window.location.reload();
                        } else {
                            alert(response.data.message)
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        alert('Somthing went wrong please try again')
                        setLoading(false);
                    })
            } catch (error) {

            }
        } else {
            // alert('No');
        }

    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <SideNav header={"Update Co-Traveler"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container>
                        <Card className='px-5 py-3 mt-3'>
                            <Row className='mb-1'>
                                {/* <h6 className='bg-light-red py-2 px-3'>User Details  &nbsp;<span className='badge badge-danger float-right'>1</span></h6> */}
                                <h6 className='bg-light-red py-2 px-3'>Update Co-Traveler  </h6>
                                <Divider />
                            </Row>

                            <Row >
                                <Col xs={12} sm={12} md={3} >
                                    <Box className="setBox ">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><PersonIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Title <span className='text-danger'>*</span></div>
                                            </div>
                                        </div>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={userDetails.title}
                                                placeholder="Title *"
                                                onChange={(handleTitleChange)}
                                                error={userDetailsErr.title}
                                            >
                                                <MenuItem key={1} value={'mr'}>Mr</MenuItem>
                                                <MenuItem key={2} value={'ms'}>Ms</MenuItem>
                                                <MenuItem key={3} value={'mrs'}>Mrs</MenuItem>

                                            </Select>
                                            {/* {userDetailsErr.title !== null && (<FormHelperText style={{ color: "#d32f2f" }}>{userDetailsErr.title}</FormHelperText>)} */}
                                        </FormControl>
                                    </Box>
                                </Col>

                                <Col xs={12} sm={12} md={3} >
                                    <Box className="setBox ">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><PersonIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">First Name <span className='text-danger'>*</span></div>
                                            </div>
                                        </div>
                                        <TextField
                                            autoComplete="given-name"
                                            name="firstName"
                                            required
                                            fullWidth
                                            id="firstName"
                                            placeholder="First Name"
                                            autoFocus
                                            value={userDetails.firstName}
                                            onChange={handleChange}
                                            error={userDetailsErr.firstName}
                                            helperText={userDetailsErr.firstName}
                                        />
                                    </Box>
                                </Col>
                                <Col xs={12} sm={12} md={3}>
                                    <Box className="setBox ">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><PersonIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Middle Name</div>
                                            </div>
                                        </div>
                                        <TextField
                                            autoComplete="given-name"
                                            name="middleName"
                                            required
                                            fullWidth
                                            id="middleName"
                                            placeholder="Middle Name"
                                            autoFocus
                                            value={userDetails.middleName}
                                            onChange={handleChange}
                                            error={userDetailsErr.middleName}
                                            helperText={userDetailsErr.middleName}
                                        />
                                    </Box>
                                </Col>
                                <Col xs={12} sm={12} md={3}>
                                    <Box className="setBox ">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><PersonIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Last Name <span className='text-danger'>*</span></div>
                                            </div>
                                        </div>
                                        <TextField
                                            autoComplete="given-name"
                                            name="lastName"
                                            required
                                            fullWidth
                                            id="lastName"
                                            placeholder="Last Name"
                                            autoFocus
                                            value={userDetails.lastName}
                                            onChange={handleChange}
                                            error={userDetailsErr.lastName}
                                            helperText={userDetailsErr.lastName}
                                        />
                                    </Box>
                                </Col>
                                {/* <Col xs={12} sm={12} md={4}>
                                    <Box className="setBox">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><MailIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Email ID</div>
                                            </div>
                                        </div>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            placeholder="Email Address"
                                            name="email"
                                            inputProps={{ readOnly: true }}
                                            autoComplete="email"
                                            value={userDetails.email}
                                            onChange={handleChange}
                                            error={userDetailsErr.email}
                                            helperText={userDetailsErr.email}
                                        />
                                    </Box>
                                </Col> */}
                                <Col xs={12} sm={12} md={4}>
                                    <Box className="setBox">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><LocationOnIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Country <span className='text-danger'>*</span></div>
                                            </div>
                                        </div>
                                        {userDetails.country !== "" && (
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={countrylist}
                                                    getOptionLabel={(option) => option.name}
                                                    value={countrylist.filter(x => x.name === userDetails.country)[0]}
                                                    renderInput={(params) => <TextField {...params} error={userDetailsErr.country} />}
                                                    onChange={(event, newValue) => {
                                                        if (newValue !== null) {
                                                            setUserDetails({ ...userDetails, country: newValue.name, countryCode: newValue.country_code, countryOrigin: newValue.origin });
                                                            setUserDetailsErr({ ...userDetailsErr, country: null, countryCode: null });
                                                            getCityList(newValue.origin);
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        )}
                                    </Box>
                                </Col>
                                <Col xs={12} sm={12} md={4}>
                                    <Box className="setBox">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><PhoneAndroidIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Phone Number <span className='text-danger'>*</span></div>
                                            </div>
                                        </div>
                                        <Box className="d-flex w-100">
                                            <FormControl style={{ flexBasis: "40%" }}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="countryCode"
                                                    placeholder="Country Code"
                                                    name="countryCode"
                                                    value={userDetails.countryCode}
                                                    inputProps={{ readOnly: true }}
                                                />
                                            </FormControl>

                                            <TextField
                                                required
                                                fullWidth
                                                id="conatct"
                                                placeholder="Contact Number"
                                                name="phone"
                                                autoComplete="contactNumber"
                                                value={userDetails.phone}
                                                onChange={handlePhoneChange}
                                                error={userDetailsErr.phone}
                                                helperText={userDetailsErr.phone}
                                            />
                                        </Box>
                                    </Box>
                                </Col>


                                {((userDetails.country || userDetails) === "India" && userDetails.state !== "" && states.length > 0) && (
                                    <Col xs={12} sm={12} md={4}>
                                        <Box className="setBox">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><LocationOnIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">State <span className='text-danger'>*</span></div>
                                                </div>
                                            </div>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={states}
                                                getOptionLabel={(option) => option.en_name}
                                                value={states?.filter(x => x.origin === userDetails.state)[0]}
                                                renderInput={(params) => <TextField {...params} error={userDetailsErr.state} />}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) {
                                                        setUserDetails({ ...userDetails, state: newValue.origin });
                                                        setUserDetailsErr({ ...userDetailsErr, state: null });
                                                    }
                                                }}
                                            />

                                        </Box>
                                    </Col>
                                )}

                                <Col xs={12} sm={12} md={4}>
                                    {(cities.length > 0 && userDetails.city !== "") && (
                                        <Box className="setBox">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><LocationOnIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">City <span className='text-danger'>*</span></div>
                                                </div>
                                            </div>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={cities}
                                                getOptionLabel={(option) => option.destination}
                                                value={cities.filter(x => x.origin === userDetails.city)[0]}
                                                renderInput={(params) => <TextField {...params} error={userDetailsErr.city} />}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) {
                                                        setUserDetails({ ...userDetails, city: newValue.origin });
                                                        setUserDetailsErr({ ...userDetailsErr, city: null });
                                                    }
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Col>
                                <Col xs={12} sm={12} md={4}>
                                    <Box className="setBox">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><PersonIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Relationship <span className='text-danger'>*</span></div>
                                            </div>
                                        </div>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={relation}
                                                onChange={handleRelationChange}
                                            >
                                                <MenuItem value={'mother'}>Mother</MenuItem>
                                                <MenuItem value={'father'}>Father</MenuItem>
                                                <MenuItem value={'spouse'}>Spouse</MenuItem>
                                                <MenuItem value={'children'}>Children</MenuItem>
                                                <MenuItem value={'others'}>Others</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Col>
                                <Col xs={12} md={12} className='text-end'>
                                    <Button onClick={() => handleUpdate()} variant='contained' size="medium" color='success' className='mt-2'>Update</Button>
                                </Col>
                            </Row>

                            <Row className='mt-3 mb-3'>
                                <h6>Passport & other details</h6>
                                <Divider></Divider>
                            </Row>

                            <Row>
                                {(userDetails.country === "India" || userDetails.country === "") && (
                                <Col xs={12} md={3}>
                                    <Box className="setBox">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><RecentActorsIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">PAN Card <span className='text-danger'>*</span></div>
                                            </div>
                                        </div>
                                        <TextField
                                            required
                                            fullWidth
                                            id="pancard"
                                            placeholder="PAN Card"
                                            name="pancard"
                                            autoComplete="panCard"
                                            value={passportDetails.pancard}
                                            onChange={handlePassportDetails}
                                            error={passportDetailsErr.pancard}
                                            helperText={passportDetailsErr.pancard}
                                            inputProps={{ style: { textTransform: "uppercase" } }}
                                        />
                                    </Box>
                                </Col>
                                )}
                                <Col xs={12} md={3}>
                                    <Box className="setBox">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><CalendarMonthIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Date of Birth <span className='text-danger'>*</span></div>
                                            </div>
                                        </div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    className={`${passportDetailsErr.dateOfBith ? 'error-border' : ''}`}
                                                    format='DD-MM-YYYY'
                                                    placeholder="DD-MM-YYYY"
                                                    maxDate={dayjs()}
                                                    value={dateOfBith}
                                                    onChange={(newValue) => { setDateOfBith(newValue); setPassportDetailsErr({ ...passportDetailsErr, dateOfBith: null }) }}
                                                />
                                            </DemoContainer>
                                            {passportDetailsErr.dateOfBith !== null && (<FormHelperText style={{ color: "#d32f2f" }}>{passportDetailsErr.dateOfBith}</FormHelperText>)}
                                        </LocalizationProvider>
                                    </Box>
                                </Col>
                                <Col xs={12} md={3}>
                                    <Box className="setBox">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><RecentActorsIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Passport Number</div>
                                            </div>
                                        </div>
                                        <TextField
                                            required
                                            fullWidth
                                            id="passport"
                                            placeholder="Passport number"
                                            name="passport"
                                            value={passportDetails.passport}
                                            onChange={handlePassportDetails}
                                            error={passportDetailsErr.passport}
                                            helperText={passportDetailsErr.passport}
                                            inputProps={{ style: { textTransform: "uppercase" } }}
                                        />
                                    </Box>
                                </Col>
                                <Col xs={12} md={3}>
                                    <Box className="setBox">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><CalendarMonthIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Passport Date of Issue</div>
                                            </div>
                                        </div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    className={`${passportDetailsErr.passportIssue ? 'error-border' : ''}`}
                                                    format='DD-MM-YYYY'
                                                    placeholder="DD-MM-YYYY"
                                                    maxDate={dayjs()}
                                                    value={passportIssue}
                                                    onChange={(newValue) => { setPassportIssue(newValue); setPassportDetailsErr({ ...passportDetailsErr, passportIssue: null }) }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        {passportDetailsErr.passportIssue !== null && (<FormHelperText style={{ color: "#d32f2f" }}>{passportDetailsErr.passportIssue}</FormHelperText>)}
                                    </Box>
                                </Col>
                            </Row>
                            <Row>
                            <Col xs={12} md={3}>
                                    <Box className="setBox">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><CalendarMonthIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Passport Date of Expiry</div>
                                            </div>
                                        </div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    className={`${passportDetailsErr.passportExpiry ? 'error-border' : ''}`}
                                                    format='DD-MM-YYYY'
                                                    placeholder="DD-MM-YYYY"
                                                    value={passportExpiry}
                                                    onChange={(newValue) => { setPassportExpiry(newValue); setPassportDetailsErr({ ...passportDetailsErr, passportExpiry: null }) }}
                                                //     onChange={(newValue) => {if(newValue > new Date()){
                                                //         setPassportExpiry(newValue);
                                                //         setPassportDetailsErr({...passportDetailsErr, passportExpiry: null});
                                                //     }
                                                //     else if(formatDate(newValue) === formatDate(new Date())){
                                                //         setPassportExpiry(newValue);
                                                //         setPassportDetailsErr({...passportDetailsErr, passportExpiry: null});
                                                //     }
                                                // else{
                                                //     setPassportExpiry(newValue);
                                                //     setPassportDetailsErr({...passportDetailsErr, passportExpiry: null});
                                                //     alert("Your passport is expired");

                                                // }}}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        {passportDetailsErr.passportExpiry !== null && (<FormHelperText style={{ color: "#d32f2f" }}>{passportDetailsErr.passportExpiry}</FormHelperText>)}
                                    </Box>
                                </Col>
                                <Col xs={12} sm={12} md={3}>
                                    {passportDetails.country !== "" && (
                                        <Box className="setBox">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><LocationOnIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">Passport Issuing Country</div>
                                                </div>
                                            </div>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={countrylist}
                                                getOptionLabel={(option) => option.name}
                                                value={countrylist.filter(x => x.name === passportDetails.country)[0]}
                                                renderInput={(params) => <TextField {...params} error={passportDetailsErr.country} />}
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) {
                                                        setPassportDetails({ ...passportDetails, country: newValue.name, countryOrigin: newValue.origin });
                                                        setPassportDetailsErr({ ...passportDetailsErr, country: null });
                                                    }
                                                }}
                                            />
                                        </Box>
                                    )}
                                    {passportDetailsErr.country !== null && (<FormHelperText style={{ color: "#d32f2f" }}>{passportDetailsErr.country}</FormHelperText>)}
                                </Col>
                                <Col xs={12} sm={12} md={3}>
                                    <Box className="setBox">
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><PersonPinCircleIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Nationality</div>
                                            </div>
                                        </div>
                                        <TextField
                                            required
                                            fullWidth
                                            id="nationality"
                                            placeholder="Nationality"
                                            name="nationality"
                                            value={passportDetails.nationality}
                                            onChange={handlePassportDetails}
                                            error={passportDetailsErr.nationality}
                                            helperText={passportDetailsErr.nationality}
                                        />
                                    </Box>
                                </Col>
                               
                                <Col xs={12} sm={12} md={3} className='text-end'>
                                    <Button disabled={loading} onClick={() => checkPassportValidation()} variant='contained' size="medium" color='success' className='mb-3 mt-2'>Update</Button>
                                </Col>
                            </Row>
                            {docFile.length !== 0 &&
                                <Row className='mb-2 mt-3 '>
                                    <h6>Uploaded Documents</h6>
                                    <Divider></Divider>
                                    <TableContainer>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><b>File type</b></TableCell>
                                                    <TableCell align="right"><b>Document</b></TableCell>
                                                    <TableCell align="right"><b>Action</b></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {docFile?.map((row) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                    >
                                                        <TableCell>
                                                            {docTypeArr[row?.doc_type]}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => handleDocModal(row)} size="small" color='success' className='float-right'>View</Button>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Button onClick={() => handleDocDelete(row)} size="small" color='error' className='mx-2'><DeleteIcon /></Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Row>
                            }
                            <Row className='mb-2 mt-3'>
                                <h6>Upload Documents</h6>
                                <Divider></Divider>
                            </Row>

                            {documents.map((item, index) =>
                                <Row>
                                    <Col xs={12} sm={12} md={4}>
                                        <Box className="setBox">
                                            <div className="userbox">
                                                <div className="user-ico"><SvgIcon><AttachmentIcon /></SvgIcon></div>
                                                <div className="user-details">
                                                    <div className="tlthedae">Select Document</div>
                                                </div>
                                            </div>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={item.selectedDocument}
                                                    placeholder="Select Document "
                                                    name="selectedDocument"
                                                    onChange={(e) => onDocumentChange(e, index)}
                                                >
                                                    {Object.keys(docTypeArr).map(function (key, index) {
                                                        return (
                                                            <MenuItem value={key}>{docTypeArr[key]}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Col>
                                    <Col xs={12} md={7}>
                                        <div className="userbox">
                                            <div className="user-ico"><SvgIcon><AttachmentIcon /></SvgIcon></div>
                                            <div className="user-details">
                                                <div className="tlthedae">Upload Document</div>
                                            </div>
                                        </div>
                                        <div className="fileupload-view">
                                            <div className="kb-data-box">

                                                <form onSubmit={FileUploadSubmit}>
                                                    <div className='d-flex'>
                                                        <div className="kb-file-upload">
                                                            <div className="file-upload-box">
                                                                <TextField type="file" id="fileupload" className="file-upload-input" onChange={(e) => InputChange(e, index)} multiple />
                                                                {/* <span>Drag and drop or */}
                                                                <span className="file-link">Choose your files</span>
                                                                {/* </span> */}
                                                            </div>
                                                        </div>


                                                        <div className="kb-attach-box">
                                                            {
                                                                item.files.map((data, i) => {
                                                                    const { id, filename, fileimage, datetime } = data;
                                                                    return (
                                                                        <div className="file-atc-box" key={id}>
                                                                            {
                                                                                filename.match(/.(jpg|jpeg|png)$/i) ?
                                                                                    <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                                    filename.match(/.(pdf)$/i) ?
                                                                                        <span>{filename}</span> :
                                                                                        <div className="file-image"><i className="far fa-file-alt"></i></div>

                                                                            }
                                                                            <Button variant='' size="small" color='error' onClick={() => DeleteSelectFile(id, i, index)}><HighlightOffIcon /></Button>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>

                                                </form>
                                                {Files.length > 0 ?
                                                    <div className="kb-attach-box">
                                                        <hr />
                                                        {
                                                            Files.map((data, index) => {
                                                                const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                                return (
                                                                    <div className="file-atc-box" key={index}>
                                                                        {
                                                                            filename.match(/.(jpg|jpeg|png|pdf)$/i) ?
                                                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                                <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                                        }
                                                                        <div className="file-detail">
                                                                            <h6>{filename}</h6>
                                                                            <div className="file-actions">
                                                                                <Button variant='contained' size="small" color='error' onClick={() => DeleteFile(id)}>Delete</Button>
                                                                                <a href={fileimage} className="file-action-btn" download={filename}>Download</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>

                                    </Col>

                                    <Col xs={12} md={1} className='my-auto px-0'>
                                        <div className='d-flex row-btn'>
                                            <Button variant='contained' size="medium" color='success' onClick={() => onFormAddition()}><AddCircleOutlineIcon /></Button>
                                            <Button variant='contained' size="medium" color='error' className='mx-2' onClick={() => onFormDelete(index)} disabled={documents.length > 1 ? false : true}><DeleteIcon /></Button>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col xs={12} sm={12} md={12} className='mt-3'>
                                    <Button onClick={() => handleDocUpdate()} variant='contained' size="medium" color='success' className='mb-3 float-right'>Update</Button>
                                </Col>
                            </Row>
                        </Card>
                        <Modal show={modalVisible} onHide={handleDocModal} animation={false}>
                            {/* <Modal
                                open={modalVisible}
                                onClose={() => handleDocModal()}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            > */}
                            <Box className="text-end"><Button className='modal-close-btn' color='error' onClick={() => { setModalVisible(false) }}><HighlightOffIcon /> close</Button></Box>
                            <hr className='mt-0'></hr>
                            <Box className="text-center my-3">

                                {selectedDoc?.file.split('.')[selectedDoc?.file.split('.')?.length - 1] === "pdf" ? (
                                    <object width="100%" height="400" data={`${FILE_PATH}/${selectedDoc?.doc_type}/${selectedDoc?.file}`} type="application/pdf">   </object>
                                ) : (
                                    <img src={`${FILE_PATH}/${selectedDoc?.doc_type}/${selectedDoc?.file}`} />
                                )}
                            </Box>
                        </Modal>


                       
                    </Container>
                </Box>
            </Box>
            <MainFooter />
        </>
    )
}

export default UpdateTraveler
