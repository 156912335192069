import React, {useEffect, useState} from 'react';
import { createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import './dashboard.css'
import SideNav from '../../SideNav';
import { Box } from '@mui/material';
import MainFooter from '../footer/MainFooter';
import { getAuth } from '../../utility/apiConfig';
import { API_BASE_URL } from '../../utility/apiConstants';
import Calendar from './Calender';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {
    const [loading, setLoading] = useState(false);
    const [itineraryList, setItineraryList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getUserAccountDetails();
        getItineraryList();
    }, []);

    const getUserAccountDetails = () => {
        setLoading(true);
        try {
            getAuth(API_BASE_URL + 'user/account')
                .then((response) => {
                    // console.log("response=======>",response.data);
                    if (response.data.status) {
                        // alert(response.data.data.message)
                       // console.log("response=======>", response.data.data?.form_data);
                        let user_data = response.data.data?.form_data
                        localStorage.setItem('user_data', JSON.stringify(user_data));
                        setLoading(false);
                    } else {
                        alert(response.data.message)
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log("Error", error)
                    alert('Somthing went wrong please try again')
                    setLoading(false);
                })
        } catch (error) {
        }
    }

    function formatDate(date) {
        let d = new Date(date);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        let newDate = `${ye}-${mo}-${da}`
        return newDate;
    }

    const getItineraryList = () => {
        setIsLoading(true);
        try {
            getAuth(API_BASE_URL + `itinerary/fetch_itinerary?user_id=&activity_id=&itinerary_id=`)
                .then((response) => {
                    if (response.data && response.data.data && response.status) {
                        let match_data = response.data.data.data.filter(x=> x.itinerary_activity_details?.length > 0)?.map(x=> x.itinerary_activity_details[0])?.map(item=> {return({ start: item.match_date, end: item.match_date, title: item.teams, itinerary_id: item.itinerary_id})});
                        let hotel_data = response.data.data.data.filter(x=> x.itinerary_hotel_details[0].checkin_date !== "0000-00-00 00:00:00")?.map(x=> x.itinerary_hotel_details[0])?.map(item=> {return({ start: item.checkin_date, end: item.checkout_date, title: item.hotel_name, itinerary_id: item.itinerary_id})});
                        let flight_data = response.data.data.data.filter(x=> x.itinerary_flight_details?.length > 0)?.map(x=> x.itinerary_flight_details[0])?.map(item=> {return({start: formatDate(item[0]?.SegmentDetails[0][0]?.OriginDetails?.DateTime), end: formatDate(item[0]?.SegmentDetails[0][0]?.OriginDetails?.DateTime), title: `${item[0]?.SegmentDetails[0][0]?.OriginDetails?.CityName} - ${item[0]?.SegmentDetails[0][0]?.DestinationDetails?.CityName}`, itinerary_id: item[0].itinerary_id})});
                       //    let all_data = [...match_data, ...hotel_data]
                        // console.log("all_data", [...Array(3)].map((x,i)=> {return({
                        //     events: i === 0 ? match_data : i === 1 ? hotel_data : flight_data, 
                        //     backgroundColor: i === 0 ? "brown" : i === 1 ? "blue" : "red", 
                        //     borderColor: i === 0 ? "brown" : i === 1 ? "blue" : "red"
                        // })}))
                        let event_data = [...Array(3)].map((x,i)=> {return({
                                    events: i === 0 ? match_data : i === 1 ? hotel_data : flight_data, 
                                    backgroundColor: i === 0 ? "brown" : i === 1 ? "blue" : "red", 
                                    borderColor: i === 0 ? "brown" : i === 1 ? "blue" : "red"
                                })})
                        setItineraryList(event_data);
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                        setItineraryList([]);
                    }
                }).catch((error) => {
                    alert(error.message);
                    setIsLoading(false);
                    setItineraryList([]);
                })
        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <SideNav header={"Dashboard"} />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Container maxWidth="lg" sx={{ mt: 3, }}>
                        <Grid container spacing={2}>
                            {/* <Grid  item xs={12} md={12}>
                                <Link to="/profile"><Alert severity="warning">Please complete your profile - 90% done</Alert></Link>
                            </Grid> */}
                            <Grid item xs={12} md={12}>
                                <Calendar itineraryList={itineraryList}/>
                            </Grid>
                            {/* <Grid item xs={12} md={3}>
                                <div class="card bg-c-blue order-card">
                                    <div class="card-block">
                                        <h6 class="m-b-20">Request Received</h6>
                                        <h2 class=""><span>100</span></h2>
                                        <p class="mb-0">Total Request</p>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <div class="card bg-c-green order-card">
                                    <div class="card-block">
                                        <h6 class="m-b-20">Request Received</h6>
                                        <h2 class=""><span>30</span></h2>
                                        <p class="mb-0">Completed Request</p>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <div class="card bg-c-yellow order-card">
                                    <div class="card-block">
                                        <h6 class="m-b-20">Request Received</h6>
                                        <h2 class=""><span>40</span></h2>
                                        <p class="mb-0">Pending Request</p>
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <div class="card bg-c-pink order-card">
                                    <div class="card-block">
                                        <h6 class="m-b-20">Request Received</h6>
                                        <h2 class=""><span>30</span></h2>
                                        <p class="mb-0">Rejected Request</p>
                                    </div>
                                </div>
                            </Grid> */}
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <MainFooter />
        </>
    );
}